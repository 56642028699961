import React, { Component } from 'react'

class SearchBar extends Component {

  state = {
    search_term: ""
  }

  searchCourses = (event) => {
    event.preventDefault();
    this.props.submitHandler(this.state.search_term);
  }

  updateSearchTerm = (event) => {
    this.setState({ search_term: event.target.value })
  }

  render() {
    const { search_term } = this.state
    return (
      <form className="mw6 center pa5 pv5-ns" onSubmit={this.searchCourses}>
        <div className="w-100 relative">
          <input
            type="text"
            aria-label="Search"
            className="w-100 br-pill ba pl4 bg-white bw-0-06rem b--dark-gray f5 avenir-heavy pa-0-8rem tc-gray ma0 pointer"
            required placeholder="Search"
            value={search_term}
            onChange={this.updateSearchTerm}
          />
          <button type="submit" aria-label="Search" className="flex justify-center items-center absolute right-0 top-0 pa0 ma0 bn bg-transparent pointer">
            <div className="bg-light-gray h2-875 w2-875 dib ba b--dark-gray bw-0-06rem br-100"></div>
            <i className="black far fa-search f5 absolute"></i>
          </button>
        </div>
      </form>
    )
  }
}
export default SearchBar
