import React from "react";
import cn from "classnames";
import NavLink from "./NavLink";
import NavDropdown from "./NavDropdown";

class HeaderNavLinks extends React.Component {
  state = {
    isOpen: false,
  };

  pathname = window.location.pathname;
  checkHome = () => this.pathname == "/";
  checkLinks = (href) => this.pathname.includes(href);

  toggleDropdown = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const {
      courses,
      enquiryPath,
      aboutUsPath,
      aboutProgramPath,
      collaboratorsPath,
      testimonialsPath,
      accessibilityPath,
      inclusionPortalLoginPath,
    } = this.props;
    const { isOpen } = this.state;
    const containerFlex =
      "flex flex-column flex-row-l items-center items-stretch-l justify-between-l";
    const containerPosition = "header__nav-links--position";
    const containerDimensions = "header__nav-links--dimensions pv3 pv0-l w-100";
    return (
      <React.Fragment>
        <div
          className={`${cn({
            closed: !isOpen,
          })} bg-cream shadow-1 shadow-0-l overflow-y-scroll overflow-visible-l ${containerDimensions} ${containerFlex} ${containerPosition}`}
        >
          <NavLink href="/" checkActive={this.checkHome}>
            Home
          </NavLink>
          <NavDropdown
            label={"About"}
            ariaLabel="Open a dropdown menu with links about the Inclusion platform."
            relativeToButton={true}
            dropdownStyles="flex justify-center flex-column-l flex-wrap"
          >
            <NavLink href={aboutProgramPath} checkActive={this.checkLinks}>
              About the program
            </NavLink>
            <NavLink href={aboutUsPath} checkActive={this.checkLinks}>
              About us
            </NavLink>
            <NavLink href={collaboratorsPath} checkActive={this.checkLinks}>
              Our collaborators
            </NavLink>
            <NavLink href={testimonialsPath} checkActive={this.checkLinks}>
              Testimonials
            </NavLink>
            <NavLink href={accessibilityPath} checkActive={this.checkLinks}>
              Accessibility
            </NavLink>
          </NavDropdown>
          <NavDropdown
            label="Courses"
            ariaLabel="Open a dropdown menu with links to course information."
            dropdownStyles="left-0 right-0"
          >
            {courses.map((course) => (
              <NavLink
                key={course.title}
                href={course.path}
                checkActive={this.checkLinks}
              >
                <img className="mw2 mr3" alt="" src={course.logo} />{" "}
                {course.title}
              </NavLink>
            ))}
          </NavDropdown>
          {/* TODO: Add pricing link back after Stripe provisioned */}
          {/* <NavLink href={pricingPath} checkActive={this.checkLinks}>Pricing</NavLink> */}
          <span className={"grey-hr w-90 dn-l mb4"} />
          <a className={"Button-header mb3 dn-l"} href={enquiryPath}>
            Enquire Now
          </a>
          <a
            className={
              "mv3 fw5 no-underline db dn-l tc dark-gray inclusion-grape-hover"
            }
            href={inclusionPortalLoginPath}
          >
            Login
          </a>
        </div>
        <div className="flex dn-l">
          <a
            className={`pointer br-100 f6 ${cn({
              "bg-washed-dark-gray dark-gray": !isOpen,
              "bg-washed-inclusion-grape inclusion-grape": isOpen,
            })} ba tc pa2 h2 w2`}
          >
            <i
              className="fa fa-bars color-inherit"
              onClick={() => this.toggleDropdown()}
            />
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderNavLinks;
