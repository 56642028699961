import React from 'react'
import quotationIcon from '../../../assets/images/quotation-icon.svg'

const TestimonialCard = (props) => {
  const { author, organizationDetails, logo, cardClass } = props

  return(
    <blockquote className={ cardClass || 'testimonial-card'}>
      <div className='testimonial-card__quotations'>
        <img src={quotationIcon} alt={'quotations'}/>
      </div>
      <div className='testimonial-card__copy'>
        {props.children}
      </div>
      <div className='testimonial-card__footer'>
        <div className='testimonial-card__footer__organization-details'>
          <p className='testimonial-card__footer__organization-details__author'>{author}</p>
          <p className='testimonial-card__footer__organization-details__organization'>{organizationDetails}</p>
        </div>
        { logo &&
          <div className='testimonial-card__footer__logo'>
            <img src={logo.src} alt={logo.alt}/>
          </div>
        }
      </div>
    </blockquote>
  )
}

export default TestimonialCard