import React from 'react'

class CarouselArrow extends React.Component {

  render () {
    const { className, facingForward, onClick } = this.props
    return (
      <button type="button" onClick={onClick} className={`${className} carousel-arrow `} aria-label={facingForward ? 'View next' : 'View previous'}>
        <i className={`far fa-lg fa-chevron-${facingForward ? 'right' : 'left'}`}></i>
      </button>
    )
  }
}
export default CarouselArrow