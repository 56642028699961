import React, { Component } from 'react'
import noScroll from 'no-scroll'
import PlyrVideo from './PlyrVideo'
import ReactDom from 'react-dom'
import FocusTrap from 'focus-trap-react'
import CloseModalSvg from '../shared/CloseModalSvg'

export default class VideoModal extends Component {
  constructor(props){
    super(props)
    this.state = {}
    this.videoElement = React.createRef()
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    this.props.open? noScroll.on() : noScroll.off()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    noScroll.off()
  }

  close = (event) => {
    this.props.onClose()
  }

  updateWindowDimensions = () => this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });

  calculateSize = () => {
    let ratio = this.parseAspect()
    let smallestSide = Math.min(this.state.windowWidth, this.state.windowHeight);
    let scale = parseFloat(this.props.percentWidth) / 100
    return {
      width: ((smallestSide * ratio.width) * scale) || 1,
      height: ((smallestSide * ratio.height) * scale) || 1
    }
  }

  parseAspect = () =>  {
    let ratio = this.props.aspectRatio.split(':')
    return {
      width: 1,
      height: parseFloat(ratio[1])/parseFloat(ratio[0])
    }
  }

  closeButton = () => {
    return (
      <button onClick={this.close} className={'modal-close-btn'}>
        <CloseModalSvg/>
      </button>
    )
  }

  blockEvent= (event) => {
    event.stopPropagation()
  }

  render() {
    const modalClasses = this.props.modalClasses === undefined ? '' : this.props.modalClasses;
    const overlayClasses = this.props.overlayClasses  === undefined ? '' : this.props.overlayClasses;
    const videoSize = this.calculateSize()
    
    return ReactDom.createPortal(
      (
        <div style={{display: this.props.open? 'block' : 'none' }} className={'fixed z-max h-100 w-100'} >
          <div className={`modal-overlay ${overlayClasses}`} onClick={this.close} >
            <FocusTrap active={this.props.open}>
             <div className={`br4 mw-100 modal ${modalClasses}`} onClick={this.blockEvent}>
                {this.closeButton()}
                <PlyrVideo stopped={!this.props.open} video={this.props.video} subtitles={this.props.subtitles} width={videoSize.width} height={videoSize.height} />
              </div>
            </FocusTrap>
          </div>
        </div>
      ),
      document.body
    )
  }
}