import React from 'react'

export default function Sponsors(props) {
  return (
    <React.Fragment>
      <div className="flex flex-column flex-row-ns items-center-ns justify-start pb3 pb0-ns">
        <p className="fw7 f6 p-l tc-dark-gray ma0 pr1-5rem-ns pb3 pb0-ns">
          Brought to you by
        </p>
        <div className='flex'>
          <div className="w-6rem pr1-5rem">
            <a className="pointer link grow" href={props.sbsPath} target="_blank">
              <img className="grow h2" alt="SBS" src={props.sbsLogoPath} />
            </a>
          </div>
          <div className="w4">
            <a className="pointer link" href={props.iesPath} target="_blank">
              <img className="grow h2" alt="Internation Education Services" src={props.iesLogoPath} />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
