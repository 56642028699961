import React from 'react'
import SearchBar from './SearchBar'

function triggerSearch(search_term) {
  console.log(search_term)
  // replace with search functions
}

function SearchBarDemo() {
  return (
    <SearchBar submitHandler = {triggerSearch}>
    </SearchBar>
  )
}

export default SearchBarDemo