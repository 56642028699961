import React, { Component } from 'react';

class ReadMoreButton extends Component {
	state = {
    isExpanded: false,
	};

  toggle = () => {
    const toggled = !this.state.isExpanded
    this.setState({
      isExpanded: toggled,
    })
    document.getElementById("expandable").setAttribute("visible", toggled);
  }

	render() {
    const {isExpanded} = this.state;
    return(
      <button className = "pv1 mt3 mb4 dn-l main-blue fw6 f5 f4-ns pointer bn flex items-center bg-transparent" onClick={this.toggle}>
        {isExpanded ? "Read less" : "Read more"}
        <i className={`ml2 far fa-lg fa-angle-${isExpanded ? "up" : "down"}`}></i>
      </button>
    )
  }
}
export default ReadMoreButton;
