import React from "react"
import Slider from "react-slick"
import CarouselArrow from './CarouselArrow'

class Carousel extends React.Component {
  render () {
    const { children } = this.props
    const sliderProps = {
      prevArrow: <CarouselArrow facingForward={false} />,
      nextArrow: <CarouselArrow facingForward={true} />,
      dots: true,
      appendDots: dots => (
        <div>
          <ul className="flex flex-row ma0 pa0 list items-center">{dots}</ul>
        </div>
      ),
      customPaging:() => (
        <div className="h-100 ph1 tc-gray flex justify-center align-center">
          <i className="fas fa-circle o-50"></i>
        </div>
      ),
      slidesToScroll: 1,
      slidesToShow: 2,
      autoplaySpeed: 500,
      infinite: true,
      focusOnSelect: true,
    };
    return (
      <Slider {...sliderProps}>
        {children}
      </Slider>
    )
  }
}

export default Carousel