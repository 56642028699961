import React from "react";
import withLabel from "./withLabel";
import withFormBindings from "./withFormBindings";

const RangeRadioButton = (props) => {
  const { id, name, range } = props
  const value = range.size
  const option_id = `${id}_${value}`
  return (
    <div className="mt2">
      <input className="box-radio-input absolute" 
             type="radio" 
             value={value}
             name={name}
             defaultChecked={value < 50}
             id={option_id} />
      <label className="db tc pv2 ph3 pointer ba b--black-20 br1 bg-white light-silver box-radio-label" htmlFor={option_id}>
        {range.label}
      </label>
    </div>
  )
}

const RangeSelectField = (props) => {
  const { options } = props
  return (
    <div className="flex flex-column flex-row-ns flex-wrap justify-between mw6">
      {options.map((range, i) => <RangeRadioButton key={i} range={range} {...props} />)}
    </div>
  )
}

export default withFormBindings(withLabel(RangeSelectField))