import React, { Component } from 'react'
import SVGCroppedImage from './SVGCroppedImage'

export default class Slide2Thumbnail extends Component {

  constructor(props)
  {
    super(props)

    this.state = {
      open: false
    }
  }

  openModal = () => {
    this.setState({
      open: true
    })
  }

  closeModal = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {open} = this.state;

    return (
      <SVGCroppedImage imagePath={this.props.image} rotate={70} offsetX={-60} offsetY={-90.5} scale={3} borderRadius={0.1} classNames={this.props.classNames} />
    )
  }
}
