import React from "react";

const ExpandableCard = (props) => {
  const { title, open, clickHandler, children } = props
  return (
    <section className="bg-cream br1 shadow mv4">
      <div className="flex justify-between items-center pointer pa4 dim" onClick={clickHandler}>
        <h2 className={`f3 fw2 ma0 ${open ? "tc-main-blue" : "mid-gray"}`}>{title}</h2>
        <i className={`fal fa-2x light-silver ${open ? "fa-minus" : "fa-plus"}`}></i>
      </div>
      <div className={`overflow-hidden ${open ? "max-height-huge" : "max-height-0"}`}>
        <div className="ph4 pb4">{children}</div>
      </div>
    </section>
  )
}


export default ExpandableCard