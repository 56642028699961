import React from "react";
import Carousel from '../shared/Carousel'
import TestimonialCard from './TestimonialCard'


const TestimonialCarousel = (props) => {
  return(
    <Carousel>
      {/* <BupaTestimonial cardClass={'testimonial-card--carousel'}/> */}
      <TestimonialCard author={'Anna Di Gorgio'} organizationDetails={'Head of Diversity, Equity and Inclusion - BlueScope Steel'} logo={{src: '/participant-logos/bluescope-carousel.png', alt: 'Bluescope Steel Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>The <strong>SBS First Nations Inclusion Cultural Awareness Training</strong> has been instrumental in fostering a more inclusive and culturally competent workforce, <strong>empowering our team to embrace diversity and equity</strong> with greater understanding and respect. I particularly appreciated the first-hand video insights based on lived experience.</p>        
      </TestimonialCard>
      <TestimonialCard author={'Corrie Playford-Browne'} organizationDetails={'Organisational Development Lead - Diversity & Inclusion, Bupa'} cardClass={'testimonial-card--carousel'}>
      <p>We have enjoyed the <strong>Core Inclusion</strong> and <strong>First Nations</strong> awareness courses. We have received an 
        outpouring of support from our employees who have really enjoyed the <strong>engaging, 
        high-quality production</strong> of both modules. We have seen our completion rates 
        increase to numbers we’ve never seen before. The <strong>modules have been so 
        successful</strong>…and the team have been so accommodating and easy to work with. Thank you SBS!
      </p>
      </TestimonialCard>      
      <TestimonialCard author={'Jodi Rosenthal'} organizationDetails={'Head of People and Culture, QMS Media'} logo={{src: '/participant-logos/qms-carousel.png', alt: 'QMS Media Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>As a self-paced, online platform consisting of <strong>bite-sized videos</strong>, the <strong>SBS Inclusion Program met our criteria</strong> and then some. The focus on storytelling aligns with our wider Diversity and Inclusion strategy, which is about bringing people in and building education through empathy and lived experience, and the content itself is <strong>accessible, interesting, and engaging.</strong></p>        
      </TestimonialCard>  
      <TestimonialCard author={'Vanessa Gilbert'} organizationDetails={'Learning and Development Manager, BGC Australia'} logo={{src: '/participant-logos/bcg-logo.jpg', alt: 'BGC Australia Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>The <strong>SBS modules</strong> have helped support us in continuing the education and <strong>awareness of diversity and inclusion</strong> issues. The video formats and storytelling from real people have had <strong>great impact</strong>, on those who were unaware of the issues, to those who have personally been impacted. Additionally, <strong>the support from SBS themselves has been superb</strong>, quick communication and sharing of resources has made it easy for us to continue to engage their well-developed content.</p>        
      </TestimonialCard>                    
      <TestimonialCard author={'Client'} organizationDetails={'Gilead Sciences'} logo={{src: '/participant-logos/gilead-logo-carousel.png', alt: 'Gilead Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>A <strong>well done educational event</strong>. I am a strong ally. There is always something to learn. 
        I appreciate the <strong>sharing of stories</strong> which made this a <strong>very powerful</strong> and memorable offering. 
        The platform was <strong>easy to navigate</strong>. I also appreciated the questions and the explanations. 
        Thank you! I look forward to more content.</p>
        <p><strong>Very grateful</strong> for those who <strong>shared their journeys</strong> in the videos. Thank you.</p>
      </TestimonialCard>
      <TestimonialCard author={'Cathy Capogreco'} organizationDetails={'Rail Projects Victoria, Major Transport Infrastructure Authority'} logo={{src: '/participant-logos/railproject-logo-carousel.png', alt: 'Rails Projects Victoria Logo'}} cardClass={'testimonial-card--carousel'}>
        <p>The SBS modules have really <strong>assisted to support, promote and drive diversity and inclusion initiatives in our workplace</strong>. For example, the <strong>First Nations</strong> course forms part of a blended training approach and the <strong>LGBTIQ+</strong> course is a great complimentary resource…Finally, no Diversity and Inclusion awareness day is promoted at RPV without taking the opportunity to refer our team members to the relevant module in the series. <strong>Congratulations SBS on creating such an informative and engaging series of courses.</strong></p>      
      </TestimonialCard>      
      <TestimonialCard author={'Kasia Miceli'} organizationDetails={'Organisational Development Consultant, Return To Work SA'} logo={{src: '/participant-logos/returntoworksa-logo-carousel.png', alt: 'Return to Work SA Logo'}} cardClass={'testimonial-card--carousel'}>
        <p>The <strong>SBS Inclusion Program</strong> has <strong>seamlessly integrated to our inclusion strategies</strong>, helping our employees to freely and 
        comfortably interact with their colleagues, customers and the broader community. The program is presented in an <strong>interactive, 
        informative</strong> and very <strong>engaging</strong> manner. The short, <strong>bite-size videos</strong> and activities help employees test their learning, 
        and the <strong>additional materials</strong> provide teams with the <strong>opportunity to discuss and apply</strong> their understanding. Importantly, 
        having a web-based platform ensures employees can access the training no matter where they're working, and fit it in to their schedules.</p>
      </TestimonialCard>
      <TestimonialCard author={'Sarah Young'} organizationDetails={'Group Director, ESG, oOh!media'} logo={{src: '/participant-logos/oohumissable-carousel.png', alt: 'BGC Australia Logo'}} cardClass={'testimonial-card--carousel'}>
      <p>The online <strong>First Nations course</strong> was a fundamental component of our company wide reconciliation program. It was <strong>well received by staff, and feedback was excellent</strong>, particularly with regards to the relatable and interesting nature of the content…informative and highly appreciated by our staff. The <strong>SBS team</strong> were really <strong>easy to deal with and extremely helpful</strong> with the execution of the training.</p>        
      </TestimonialCard> 
    </Carousel>
  )
}

export default TestimonialCarousel