import React, { useEffect } from 'react';
import noScroll from 'no-scroll'
import FocusTrap from 'focus-trap-react'
import CloseModalSvg from './CloseModalSvg';

const Modal = (props) => {
  const { isOpen, close, closeBtnStyles, overlayStyles, modalStyles } = props
  useEffect(() => {
    isOpen ? noScroll.on() : noScroll.off()

    return () => {
      noScroll.off()
    }
  })

  return( 
    <div style={{display: isOpen ? 'block' : 'none' }} className={'fixed z-max h-100 w-100'} >
      <div className={`${overlayStyles ? overlayStyles : "modal-overlay"}`} onClick={close} >
        <FocusTrap active={isOpen}>
          <div className={`${modalStyles ? modalStyles : "br4 mw-100 modal"}`} onClick={(event) => event.stopPropagation()}>
            <button onClick={close} className={`${closeBtnStyles ? closeBtnStyles : "modal-close-btn"}`}>
              <CloseModalSvg/>
            </button>  
            {props.children}
          </div>
        </FocusTrap>
      </div>
    </div>
  )
}

export default Modal