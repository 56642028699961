import React from 'react'
import Plyr from 'plyr/dist/plyr.polyfilled'

class PlyrVideo extends React.Component {
  constructor(props){
    super(props)
    this.playerRef = React.createRef()
    this.sources = {
      type: 'video',
      sources: [
          {
              src: this.props.video,
              type: 'video/mp4',
          },
      ],
      tracks: [
        {
          kind: 'captions',
          label: 'English',
          srclang: 'en',
          src: this.props.subtitles,
          default: true,
        }
      ],
    }

    this.player = undefined
  }

  componentDidMount= () => {
    this.loadSubtitlesTrack(this.props.subtitles)
    this.player = new Plyr(this.playerRef.current, { captions: {active: true} , autoplay: false })
    this.player.source = this.sources
    this.player.captions.active = true
  }

  componentDidUpdate = () => {
    this.props.stopped ? this.player.stop() : this.player.play()
  }

  loadSubtitlesTrack = (track) => {
    if(this.sources.tracks[0].src instanceof Blob) return
    let xhttp = new XMLHttpRequest();

      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == XMLHttpRequest.DONE && xhttp.status == 200 && xhttp.responseText) {
          // If VTT fetch succeeded, replace the src with a BLOB URL.
          let blob = new Blob([xhttp.responseText], { type: 'text/vtt' });
          //merge the current source with a new subtitle track that points to a local blob holding the subtitle data
          let copy_of_sources = this.sources
          copy_of_sources.tracks[0].src = blob
          this.sources = copy_of_sources
        }
      }

    xhttp.open("GET", track, true);
    xhttp.send();
  }

  render = () => {
    return (
      <video ref={this.playerRef} tabIndex={0} controls={true} controlsList="nodownload" preload="metadata" crossOrigin="anonymous" width={this.props.width} height={this.props.height} className="pa3 pa4-l bn" />
    )
  }
}

export default PlyrVideo;