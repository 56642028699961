import React from "react"
import cn from "classnames"

export default function NavLink(props) {
  const { href, children, checkActive } = props
  const linkClasses = `Button-text ${cn({'active': checkActive && checkActive(href)})}`
  return (
    <a tabIndex={0} href={href} className={linkClasses} >
      {children}
    </a>
  )
}
