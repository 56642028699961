import React, { Component } from "react";

class Form extends Component {

  state = {
    feedback: {}
  }

  submitViaAjax = (event) => {
    event.preventDefault()
    this.displayLoading()
    const form = event.target
    const request = new XMLHttpRequest()
    request.onload = (event) => this.displayResponse(event.target)
    request.open(form.method, form.action)
    request.send(new FormData(form))
  }

  displayResponse = (response) => response.status === 200 ? this.displaySuccess(response) : this.displayError()
  displayLoading = () => this.changeFeedback("Submitting now", "gray", "fa-spinner spin-animation")
  displaySuccess = (response) => this.changeFeedback(response.responseText, "green bulge-animation", "fa-check-circle")
  displayError = () => this.changeFeedback("Something went wrong, please try again later", "red shake-animation", "fa-exclamation-circle")
  changeFeedback = (message, messageStyles, iconStyles) => this.setState({ feedback: { message: message, messageStyles: messageStyles, iconStyles: iconStyles } })

  render () {
    const { name, url, authToken, children } = this.props
    const { message, messageStyles, iconStyles } = this.state.feedback
    return (
      <form action={url} acceptCharset="UTF-8" method="POST" onSubmit={this.submitViaAjax}>
        <div className="flex flex-column">
          <input type="hidden" name="authenticity_token" value={authToken} />
          {children.map((child, i) => React.cloneElement(child, { form: name, key: i }))}
          <input className="br2 pa3 input-reset bg-main-blue dim pointer db white f7 fw3" type="submit" value="Submit" />
          {message && 
          <div className="flex justify-end items-center mt4">
            <div className={messageStyles}>
              <i className={`mr2 fal ${iconStyles}`}></i>
              <span>{message}</span>
            </div>
          </div>}
        </div>
      </form>
    )
  }

}

export default Form