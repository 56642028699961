import React, { Component, Fragment } from "react";
import VideoModal from "./VideoModal";

export default class WatchVideoButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { open } = this.state;
    const aspectRatio = !!!this.props.videoAspectRatio
      ? "16:9"
      : this.props.videoAspectRatio;

    return (
      <Fragment>
        <button
          onClick={this.openModal}
          aria-label="Play this video for a preview of content." data-tracking-label="Video Played Button" className="link bn pointer ph4 pv3 dib no-underline br1 fw7 white inclusion-grape-hover bg-inclusion-grape bg-white-hover tc bw2 b--solid b--white b--inclusion-grape-hover tracked-s"
        >
          <i className="fas fa-play color-inherit" />
          &nbsp;
          {this.props.buttonText}
        </button>
        <VideoModal
          open={open}
          onClose={this.closeModal}
          video={this.props.video}
          subtitles={this.props.subtitles}
          aspectRatio={aspectRatio}
          percentWidth="70"
        />
      </Fragment>
    );
  }
}
