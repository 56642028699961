import React, { Component } from 'react'
import Slide2Thumbnail from './Slide2Thumbnail'

export default class Demo extends Component {
  constructor(props)
  {
    super(props)

    this.state = {
      open: false
    }
  }

  openModal = () => {
    this.setState({
      open: true
    })
  }

  closeModal = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {open} = this.state;
    
    return (
      <Slide2Thumbnail image={"https://via.placeholder.com/1600x800"} video="" videoAspectRatio="16:9"/>
    )
  }
}
