import React from "react";

const withLabel = WrappedComponent => props => {
  const { label, id, ...otherProps } = props
  return (
    <div className="mb3 pb2">
      <label htmlFor={id} className="light-silver fw6 f7 asterisk-after">{label}</label>
      <WrappedComponent {...otherProps} id={id} />
    </div>
  )
}

export default withLabel