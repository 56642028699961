import React, { Component, Fragment } from "react";
import ExpandableCard from "./ExpandableCard";
import InputField from "./InputField";
import MessageField from "./MessageField";
import RangeSelectField from "./RangeSelectField";
import Form from "./Form";

class ContactForms extends Component {

  state = {
    enquiryOpen: true
  }

  toggleCards = () => this.setState({ enquiryOpen: !this.state.enquiryOpen })

  render () {
    const { authToken, employeeRanges } = this.props
    const { enquiryOpen } = this.state
    return (
      <Fragment>
        <ExpandableCard title="Sales enquiry" open={enquiryOpen} clickHandler={this.toggleCards}>
          <Form name="enquiry" authToken={authToken} url="/enquiry/create">
            <InputField id="name" label="Your Name" required={true} type="text" />
            <InputField id="company" label="Your Company" required={true} type="text" />
            <RangeSelectField id="employees" label="Number of Employees" options={employeeRanges} />
            <InputField id="phone_number" label="Your Phone Number" required={true} type="tel" />
            <InputField id="email" label="Your Email" required={true} type="email" placeholder="example@email.com" />
            <MessageField id="message" label="Your Message" required={true} placeholder="How can we help?" />
          </Form>
        </ExpandableCard>
        <ExpandableCard title="Feedback about the CCP" open={!enquiryOpen} clickHandler={this.toggleCards}>
          <Form name="feedback" authToken={authToken} url="/feedback/create">
            <InputField id="name" label="Your Name" required={true} type="text" />
            <InputField id="email" label="Your Email" required={true} type="email" placeholder="example@email.com" />
            <MessageField id="message" label="Your Message" required={true} placeholder="How can we help?" />
          </Form>
        </ExpandableCard>
      </Fragment>
    )
  }

}

export default ContactForms