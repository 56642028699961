import React, { Component } from 'react';
import _ from 'lodash'

/*  SVG Cropped Image requires as props:
    imagePath: the path to the image
    rotate: the rotating of the shape in degrees
    offsetX: offsets the shape horizontally by a percentage of the container (-100-100) from the right most edge of the container
    offsetY: offsets the shape vertically by a percentage of the container (-100-100) from the center of the container
    scale: how large in relation to the container you would like the shape
    borderRadius: a ratio of (0.0-1.0) of how round the corners should be; 1 should give a perfect circle
*/

class SVGCroppedImage extends Component
{
    constructor(props){
      super(props);
        
      this.state = {
          svgSize: {
              width: 1,
              height: 1,
          },
          containerSize: {
            width: 1,
            height: 1
          }
      }
      
      this.offset = {
        x: this.clamp(-100,100, props.offsetX)/100,
        y: this.clamp(-100,100, props.offsetY)/100
      }

      this.forceDimensionRatio = (props.forceHeightRatio !== undefined || props.forceWidthRatio !== undefined);
      this.svgId = _.uniqueId("svgmask")
      this.container = React.createRef();
      this.img = React.createRef();
      this.resize = this.resize.bind(this);
  }

  componentDidMount()
  {
    window.addEventListener('resize', this.resize);
    this.resize();
  }
    
  componentWillUnmount()
  {
    window.removeEventListener('resize', this.resize);
  }

  resize()
  {
    if(this.forceDimensionRatio) {
      this.forceHeightRatio === undefined ? this.forceHeightBasedRatio() : this.forceWidthBasedRatio()
      return
    }

    let height = Math.max(this.container.current.clientHeight, 1);
    let width = Math.max(this.container.current.offsetWidth, 1);
    let largest = Math.max(height, width);
    let calcHeight = (((height == largest)? 1 : width/height) * height) * this.props.scale;
    let calcWidth = (((width == largest)? 1 : height/width) * width) * this.props.scale;
    
    this.setState({
      svgSize:{
        width: calcWidth,
        height: calcHeight
      },
      containerSize:{
        width: width,
        height: height
      }
    });
  }

  forceHeightBasedRatio = () => {
    let height = Math.max(this.container.current.clientHeight, 1);
    let width = Math.max(this.container.current.offsetWidth, 1);
    let calcHeight = height * this.props.scale;
    let calcWidth = height * this.props.scale;
    
    this.setState({
      svgSize:{
        width: calcWidth,
        height: calcHeight
      },
      containerSize:{
        width: width,
        height: height
      }
    });
  }

  forceWidthBasedRatio = () => {
    let height = Math.max(this.container.current.clientHeight, 1);
    let width = Math.max(this.container.current.offsetWidth, 1);
    let calcHeight = width * this.props.scale;
    let calcWidth = width * this.props.scale;
    
    this.setState({
      svgSize:{
        width: calcWidth,
        height: calcHeight
      },
      containerSize:{
        width: width,
        height: height
      }
    });
  }

  clamp (min,max,value)
  {
    return Math.min(Math.max(min,value), max);
  }

  render()
  {
    let x = this.state.svgSize.width/2 + (this.state.svgSize.width* this.offset.x);
    let y = this.state.svgSize.width * this.offset.y;
    let rotation = this.props.rotate
    let rotation_axis_x = x + (this.state.svgSize.width/2)
    let rotation_axis_y = y + (this.state.svgSize.height/2)
    let classNames = this.props.classNames === undefined ? '' : this.props.classNames;

    const maskUrl = `url(#${this.svgId})`;
    
    return (
      <React.Fragment>
          <div className={`flex align-items w-100 h-100-l vh-50 relative overflow-hidden ${classNames}`} ref={this.container}>
            {
              React.Children.map( this.props.children, child =>
                React.cloneElement(child, {
                  containerWidth: this.state.containerSize.width,
                  containerHeight: this.state.containerSize.height
                })
              )
            }
            
            <svg width="100%" height="100%" style={{width: "100%", height: "100%"}} viewBox={`0 0 ${this.state.containerSize.width} ${this.state.containerSize.height}`} >
              <image alt=""
                ref={this.img}
                xlinkHref={this.props.imagePath}
                style={{clipPath: maskUrl, WebkitClipPath: maskUrl}}
                onLoad={()=>{this.resize()}}
                x="0"
                y="0"
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid slice"
                />
            </svg>
          </div>

          <svg className="dn db-l">
            <defs>
              <clipPath id={this.svgId}>
                <rect
                rx={this.state.svgSize.width * this.props.borderRadius}
                ry={this.state.svgSize.height * this.props.borderRadius}
                width={this.state.svgSize.width}
                height={this.state.svgSize.height}
                fill="transparent"
                transform={`rotate(${rotation} ${rotation_axis_x} ${rotation_axis_y}) translate(${x} ${y})`}
                />
              </clipPath>
            </defs>
          </svg>
      </React.Fragment>
    )
  }
    
}

export default SVGCroppedImage