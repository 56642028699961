import React, { Component } from "react";
import cn from "classnames";
import { isMobile } from "react-device-detect";

export default class NavDropdown extends Component {
  state = {
    isOpen: false,
  };

  changeOpenState = (isOpen) => this.setState({ isOpen: isOpen });
  open = () => this.changeOpenState(true);
  close = () => this.changeOpenState(false);
  toggle = () => this.changeOpenState(!this.state.isOpen);

  render() {
    const {
      label,
      ariaLabel,
      children,
      relativeToButton,
      dropdownStyles,
    } = this.props;
    const { isOpen } = this.state;
    const hoverEventHandlers = isMobile
      ? {}
      : { onMouseEnter: this.open, onMouseLeave: this.close };
    const buttonClasses = `w-100 bg-transparent bn link inclusion-grape-hover pointer pa2 ma2 f5 f5-laptop fw5 ${
      isOpen ? "tc-inclusion-grape" : "dark-gray"
    }`;
    return (
      <div
        className={`pv3-l flex flex-column items-center db-l ${cn({
          "bg-seashell-l": isOpen,
          relative: relativeToButton,
        })}`}
        {...hoverEventHandlers}
      >
        <button
          aria-label={ariaLabel}
          className={buttonClasses}
          onClick={this.toggle}
        >
          {label}
          <i
            className={`ml2 fa ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          />
        </button>
        {isOpen && (
          <nav
            className={`mw-23rem absolute-l z-max below bg-seashell-l pv2 flex flex-column items-center items-start-l`}
          >
            {children}
            <div />
          </nav>
        )}
      </div>
    );
  }
}
