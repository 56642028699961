import React, { Component } from "react";
import SVGCroppedImage from "./SVGCroppedImage";
import VideoModal from "./VideoModal";

export default class FeatureImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { open } = this.state;
    const aspectRatio = !!!this.props.videoAspectRatio
      ? "16:9"
      : this.props.videoAspectRatio;

    return (
      <SVGCroppedImage
        imagePath={this.props.image}
        rotate={70}
        offsetX={-60}
        offsetY={-86.5}
        scale={4}
        borderRadius={0.1}
        classNames={this.props.classNames}
        forceHeightRatio={true}
      >
        <VideoModal
          open={open}
          onClose={this.closeModal}
          video={this.props.video}
          subtitles={this.props.subtitles}
          aspectRatio={aspectRatio}
          percentWidth="70"
        />
      </SVGCroppedImage>
    );
  }
}
